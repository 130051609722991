<template>
  <div class="about">
    <div class="newgrid newgrid-no-row-gap">
      <div class="grid-block grid-block-left-margin-1col grid-block-3col grid-medium-full">
        <h1 class="about-title">
          <span class="about-title__text utopia-blk-headline">Working with Tui</span>
        </h1>
      </div>
    </div>

    <div class="newgrid">
      <div class="grid-block grid-block-left-margin-1col grid-block-3col grid-medium-full">
        <div class="about-content">
          <p>
            Tui Media is a digital design agency. We make bespoke digital products and services,
            using cutting edge technology and user centred design.
          </p>

          <p>
            We’re not a SaaS company, hawking a product that might or might not fit your brief. Our
            work is bespoke and design-led. Much of our work is in helping large organisations
            experiment with innovative ideas. To move quickly, try stuff out, gather data, and
            repeat.
          </p>

          <p>
            We focus on long-term partnerships with trusted clients, and our work spans:
            <span class="work-spans"
              >Strategic digital consultancy, service design, UX design, prototyping, technical
              build and support and user research/user testing.</span
            >
          </p>

          <p>
            Having been around for over 30 years, we’ve had to stay on top of huge cultural and
            technical changes. None so much as the AI revolution that’s underway. Lately we’ve been
            thinking about how AI can be used in innovative and enriching ways for our clients.
            <a href="/ai-in-people-development"
              >Find out more about our latest AI prototyping in People Development here</a
            >.
          </p>

          <p>
            We’re always happy to talk about the things we do, so if you think we might be a good
            fit together, let’s talk &mdash;
            <a :href="`mailto:${email}`">{{ email }}</a>
          </p>
        </div>
      </div>
    </div>

    <div class="newgrid">
      <div class="grid-block grid-block-left-margin-1col grid-block-3col grid-medium-full">
        <ul class="clients-list">
          <li
            :data-tippy-content="name"
            v-for="{ id, name, logo } in clients"
            :key="id"
            class="clients-list__item"
          >
            <span :id="id" class="a11y-visually-hidden">{{ name }}</span>
            <img :src="logo" :alt="name" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

export default {
  name: "About",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  metaInfo() {
    return {
      title: "Working with Tui",
      link: [{ rel: "favicon", href: "./favicon.ico" }],
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { "http-equiv": "X-UA-Compatible", content: "IE=edge" },
        {
          property: "og:url",
          content: "https://www.tuimedia.com" + this.$route.fullPath,
          vmid: "ogUrl",
        },
        {
          property: "og:type",
          content: "website",
          vmid: "ogType",
        },
        {
          property: "og:title",
          content: "Working with Tui",
          vmid: "ogTitle",
        },
        {
          vmid: "ogDescription",
          property: "og:description",
          content:
            "We focus on long-term partnerships with trusted clients, and our work spans: Strategic digital consultancy, service design, UX design, prototyping, technical build and support and user research/user testing.",
        },
        {
          property: "og:image",
          content: "https://www.tuimedia.com" + require("@/assets/images/meta_thumbnail.png"),
          vmid: "ogImage",
        },
        {
          property: "og:image:width",
          content: 128,
          vmid: "ogImageWidth",
        },
        {
          property: "og:image:height",
          content: 128,
          vmid: "ogImageHeight",
        },
        {
          property: "og:site_name",
          content: "Tui Media",
        },
        {
          vmid: "twitterCard",
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:image",
          content: "https://www.tuimedia.com" + require("@/assets/images/meta_thumbnail.png"),
          vmid: "twitterImage",
        },
        {
          name: "twitter:image:alt",
          content: "Tui Duck-Rabbit logo",
          vmid: "twitterImageAlt",
        },
        {
          name: "twitter:title",
          content: "Working with Tui",
          vmid: "twitterTitle",
        },
        {
          vmid: "twitterSite",
          name: "twitter:site",
          content: "@tui_uk",
        },
        {
          vmid: "themeColor",
          name: "theme-color",
          content: "#fce600",
        },
      ],
    };
  },
  data() {
    return {
      email: "contact@tuimedia.com",
      siteTitle: "Tui Media: Independent digital design agency, London.",
      clients: [
        {
          id: "client-1",
          name: "American Express",
          logo: require("@/assets/images/client-logos/amex.svg"),
        },
        {
          id: "client-2",
          name: "Deloitte",
          logo: require("@/assets/images/client-logos/deloitte.svg"),
        },
        {
          id: "client-3",
          name: "Legal & General",
          logo: require("@/assets/images/client-logos/legal-and-general.svg"),
        },
        {
          id: "client-4",
          name: "KPMG",
          logo: require("@/assets/images/client-logos/kpmg.svg"),
        },
        {
          id: "client-5",
          name: "GlaxoSmithKline",
          logo: require("@/assets/images/client-logos/gsk.svg"),
        },
        {
          id: "client-6",
          name: "EY",
          logo: require("@/assets/images/client-logos/ey.svg"),
        },
        {
          id: "client-7",
          name: "United Nations",
          logo: require("@/assets/images/client-logos/un.svg"),
        },
        {
          id: "client-8",
          name: "British Broadcasting Corporation",
          logo: require("@/assets/images/client-logos/bbc.svg"),
        },
      ],
    };
  },
  methods: {},
  mounted() {
    tippy("[data-tippy-content]", {
      theme: "tui",
    });
  },
};
</script>

<style lang="scss" scoped>
.about {
  & a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  // Title
  &-title {
    grid-column: 3 / 7;
    margin-top: 48px;
    margin-bottom: 24px;

    &__text {
      line-height: 0.97222;
      text-wrap: balance;
    }

    // small
    @media screen and (max-width: ($medium - 1)) {
      text-align: left;
      position: relative;
      &__text {
        font-size: 33px; // 64px;
      }
    }

    // medium
    @media screen and (min-width: $medium) and (max-width: ($large - 1)) {
      &__text {
        font-size: 48px;
      }
    }

    // large
    @media screen and (min-width: $large) and (max-width: ($extra-large - 1)) {
      &__text {
        font-size: 62px;
      }
    }

    @media screen and (min-width: $large) {
      margin-top: 48px; // 95px;
      margin-bottom: 40px;
    }

    // extra-large
    @media screen and (min-width: $extra-large) {
      &__text {
        font-size: 99px;
        line-height: 1.05769;
      }
    }
  }

  // Body copy
  &-content {
    grid-column: 3 / 7;
    column-gap: 20px;
    font-family: "SlateStd-Light";

    p,
    ul {
      line-height: 1.5;

      // small
      font-size: 21px;

      // extra-large
      @media screen and (min-width: $extra-large) {
        font-size: 18px;
      }
    }

    p,
    li {
      text-wrap: pretty;
    }

    p + p,
    p + ul {
      margin-top: 18px;
    }

    p:first-child {
      font-family: "UtopiaStd-CaptionItalic";
      font-size: 24px;

      // small
      @media screen and (max-width: #{ $medium - 1 }) {
        margin-bottom: 24px;
      }

      @media screen and (min-width: $large) {
        font-size: 21px;
      }

      // extra-large
      @media screen and (min-width: $extra-large) {
        margin-bottom: 40px;
      }
    }

    p:last-child {
      font-family: "SlateStd-Medium";
      margin-bottom: 0;
    }

    em,
    strong {
      font-style: normal;
      font-family: "SlateStd-Medium";
    }

    p + ul {
      margin-top: -8px;
    }

    a {
      font-family: "SlateStd-Medium";
    }

    @media screen and (min-width: $extra-large) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      p:first-child {
        grid-column: 1 / -1;
      }

      p,
      ul {
        grid-column: 2 / -1;
        max-width: 100%;
      }

      p + ul {
        margin-top: -32px;
      }
    }
  }
}

// List
.clients-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 325px;
  margin-top: 32px;
  gap: 40px;

  @media screen and (min-width: $medium) {
    max-width: 100%;
  }

  @media screen and (min-width: $large) {
    margin-top: 0;
  }

  @media screen and (min-width: 1280px) {
    gap: 32px;
  }

  &__item {
    display: grid;
    width: calc((100% - 40px) / 2);
    height: auto;
    aspect-ratio: 1 / 1;

    @media screen and (min-width: $medium) {
      width: calc((100% - 120px) / 4);
    }

    @media screen and (min-width: 1280px) {
      width: calc((100% - 224px) / 8);
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.work-spans {
  display: block;
  margin-top: 16px;
  font-family: "SlateStd-Medium";
}
</style>
